import React from 'react';
import styled from '@emotion/styled';

/*---CVT---*/
import { Headline } from '../components/cvt/data-display/headline';
import { DeviceSizes } from '../global/global-obj';

/*---Components---*/
import Hexagon from './hexagonComponent';

const Div = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 17em;
  margin-inline: auto;
  @media (max-width: ${DeviceSizes.laptopL}) {
    margin-left: 0em;
    justify-content: center;
  }
`;
const TranslateHex = styled.div`
  margin-bottom: 1em;
  margin-left: 1em;
`;

const FlexDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  @media (max-width: ${DeviceSizes.laptop}) {
    justify-content: center;
  }
`;

function PageblockStandort({ photoSize, photo, icon, headline, children }) {
  return (
    <Div>
      <TranslateHex>
        <Hexagon
          photoSize={photoSize}
          rotation
          photo={photo}
          alt="Bild der Stadt"
        />
      </TranslateHex>
      <FlexDiv>
        {icon &&
          React.createElement(icon, {
            style: {
              height: '2em',
              width: 'auto',
              marginRight: '1em',
              marginTop: '-1em',
            },
          })}
        <div>
          <Headline variant={'h4'} text={headline} />
          {children}
        </div>
      </FlexDiv>
    </Div>
  );
}

export default PageblockStandort;
